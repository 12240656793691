import { flow, pipe } from "fp-ts/lib/function";

import { type O, RA, RNEA } from "@scripts/fp-ts";
import type { RatingAgencyU } from "@scripts/generated/domaintables/ratingAgencies";
import type { BondOfferingRelatedContent } from "@scripts/generated/models/bondOffering";
import type { RatingWithRelatedContent } from "@scripts/generated/models/rating";
import type { RatingU } from "@scripts/generated/models/ratingBase";
import type { WithStatusU } from "@scripts/generated/models/threadThrough";

export type CommonRatingsByAgency = RNEA.ReadonlyNonEmptyArray<[RatingAgencyU, RNEA.ReadonlyNonEmptyArray<WithStatusU<RatingWithRelatedContent<RatingU>>>]>;

export const relatedRatingToCommonRating = (rating: BondOfferingRelatedContent["ratings"][number]): CommonRatingsByAgency[number] => [rating[0], pipe(
  rating[1],
  RNEA.map(a => ({
    ...a,
    data: {
      record: { relatedContent: a.data.record.relatedContent, rating: a.data.record.rating.data },
      id: a.data.id,
    },
  }))
)];

export const relatedRatingsToCommonRatings: (ratings: BondOfferingRelatedContent["ratings"]) =>
  O.Option<RNEA.ReadonlyNonEmptyArray<CommonRatingsByAgency[number]>> = flow(
    RA.map(relatedRatingToCommonRating),
    RNEA.fromReadonlyArray
  );
