import { O, pipe, RA } from "@scripts/fp-ts";
import { contact, cusip6, emma, faq, linksPage } from "@scripts/generated/domaintables/featureFlags";
import { contact as contactDomain, financeTeam, linksPage as linkDomain } from "@scripts/generated/domaintables/pages";
import type { UserWithRoles } from "@scripts/generated/models/user";
import * as SR from "@scripts/generated/routers/sitesRouter";
import { emmaName } from "@scripts/literals/emma";
import { Anchor, AnchorButton } from "@scripts/react/components/Anchor";
import { constEmpty, mapOrEmpty, trueOrEmpty } from "@scripts/react/components/Empty";
import { AccentDividerSection } from "@scripts/react/components/layout/Section";
import { Markdown } from "@scripts/react/components/Markdown";
import type { JumpLink } from "@scripts/react/components/SidebarLinks";
import { contactMeta, linksMeta, teamMeta } from "@scripts/routes/routing/issuerportal/dataMeta";
import { getEditableTitleOrDefault } from "@scripts/routes/routing/ssr";
import { emmaSectionTitle, faqSectionTitle, type ResourcePageData, resources, resourcesHeaderCopy, team } from "@scripts/routes/routing/ssr/issuersites";
import { contactUsJumplinkId, emmaJumplinkId, faqJumplinkId, linksPageJumplinkId } from "@scripts/routes/routing/ssr/issuersitesJumplinkIds";
import { ifAnyEnabled, isFFEnabled } from "@scripts/syntax/featureFlags";
import { phoneWithExtension } from "@scripts/syntax/phoneNumber";

import { ContactModalCallout } from "@scripts-ssr/components/ContactModal";

import { CustomPageSection, makeCustomPageJumplinkId } from "../../components/CustomPageSection";
import { DirectSitesPageDescriptionLayoutBase } from "../../components/DirectSitesPageLayout";
import { SidebarLinksSites } from "../../components/sidebar/SidebarLinks";
import { makeJumplinkO } from "../../components/sidebar/sidebarLinksSyntax";
import { GetAlertsActionSection } from "../../components/SidebarAlert";
import { useIssuerSitesSelector } from "../../state/store";

const sidebarLinkIds = {
  emma: emmaJumplinkId,
  faq: faqJumplinkId,
  linksPage: linksPageJumplinkId,
  contactUs: contactUsJumplinkId,
};

const setupSidebarLinkSections = (
  linkIds: typeof sidebarLinkIds,
  show: { [K in keyof typeof sidebarLinkIds]: boolean },
  customPageJumpLinks: ReadonlyArray<O.Option<JumpLink>>,
  contactUsTitle: string,
  linksPageTitle: string
): ReadonlyArray<JumpLink> => RA.compact([
  makeJumplinkO(emmaSectionTitle, linkIds.emma, show.emma),
  makeJumplinkO(faqSectionTitle, linkIds.faq, show.faq),
  makeJumplinkO(linksPageTitle, linkIds.linksPage, show.linksPage),
  makeJumplinkO(contactUsTitle, linkIds.contactUs, show.contactUs),
  ...customPageJumpLinks,
]);

export const ResourcesPage = (props: { data: ResourcePageData }) => {
  const issuer = useIssuerSitesSelector("issuer");
  const pages = useIssuerSitesSelector("pages");
  const iffs = useIssuerSitesSelector("iffs");
  const user = useIssuerSitesSelector("user");
  const resourcesRouteMeta = resources({ issuerSlug: issuer.slug, issuerId: issuer.id });
  const showEmmaSection = ifAnyEnabled([emma, cusip6])(iffs);
  const showFaqSection = isFFEnabled(faq)(iffs);
  const showLinksPageSection = isFFEnabled(linksPage)(iffs);
  const showContactUsSection = isFFEnabled(contact)(iffs);
  const linksPageTitle = getEditableTitleOrDefault(linkDomain, linksMeta)(pages);
  const contactUsTitle = getEditableTitleOrDefault(contactDomain, contactMeta)(pages);
  const teamTitle = getEditableTitleOrDefault(financeTeam, teamMeta)(pages);

  const customPageJumplinks = pipe(
    props.data.customPages,
    RA.map(cp => makeJumplinkO(cp.title, makeCustomPageJumplinkId(cp), true))
  );

  const sidebarLinks = setupSidebarLinkSections(
    sidebarLinkIds,
    {
      emma: showEmmaSection,
      faq: showFaqSection,
      linksPage: showLinksPageSection,
      contactUs: showContactUsSection,
    },
    customPageJumplinks,
    contactUsTitle,
    linksPageTitle
  );
  const customPageTitles = props.data.customPages.map(_ => _.title);

  return (
    <DirectSitesPageDescriptionLayoutBase
      description={<>{resourcesHeaderCopy(showEmmaSection, showFaqSection, showLinksPageSection, showContactUsSection, linksPageTitle, contactUsTitle, customPageTitles, issuer)}</>}
      sidebarContent={
        <>
          <SidebarLinksSites
            headerLinkCustomAnchorContent={O.some("Resources")}
            jumpLinks={sidebarLinks}
            routeMeta={resourcesRouteMeta}
          />
          <GetAlertsActionSection containerKlass={"d-none-until-md"} />
        </>
      }
    >
      {trueOrEmpty(
        <AccentDividerSection title={O.some(emmaSectionTitle)} sectionId={emmaJumplinkId} klasses="accent-border-top">
          <p>View a list of our CUSIPs and see links to more information about our municipal bonds on the {emmaName} website.</p>
          <AnchorButton
            externalLinkLocation="none"
            klasses={"mt-1"}
            route={{
              title: `View ${emmaName} Links`,
              route: SR.issuersitesEmmaControllerLinks({
                issuerId: issuer.id,
                issuerSlug: issuer.slug,
                cusip9: O.none,
              }),
            }}
            target="_self"
            variant="primary"
          />
        </AccentDividerSection>
      )(showEmmaSection)}
      {trueOrEmpty(
        <AccentDividerSection title={O.some(faqSectionTitle)} sectionId={faqJumplinkId} klasses="accent-border-top">
          <p>Looking for more information about us and our bond programs? Get answers to common questions by following the link below.</p>
          <AnchorButton
            externalLinkLocation="none"
            klasses={"mt-1"}
            route={{
              title: "View FAQ",
              route: SR.issuersitesResourcesControllerFaq({
                issuerId: issuer.id,
                issuerSlug: issuer.slug,
              }),
            }}
            target="_self"
            variant="primary"
          />
        </AccentDividerSection>
      )(showFaqSection)}
      {trueOrEmpty(
        <AccentDividerSection title={O.some(linksPageTitle)} sectionId={linksPageJumplinkId} klasses="accent-border-top">
          <p>View links to related sites and other resources.</p>
          <AnchorButton
            externalLinkLocation="none"
            klasses={"mt-1"}
            route={{
              title: `View ${linksPageTitle}`,
              route: SR.issuersitesResourcesControllerLinks({
                issuerId: issuer.id,
                issuerSlug: issuer.slug,
              }),
            }}
            target="_self"
            variant="primary"
          />
        </AccentDividerSection>
      )(showLinksPageSection)}
      {trueOrEmpty(
        <AccentDividerSection title={O.some(contactUsTitle)} sectionId={contactUsJumplinkId} klasses="accent-border-top">
          <p>Still have questions? You can reach us by mail, phone, or email.</p>
          {pipe(
            props.data.address,
            mapOrEmpty(_ =>
              <div className="d-flex flex-col">
                <h5 className="mb-0">Mailing Address</h5>
                <p className="mb-0">{_.address}</p>
                <p className="mb-0">{_.city}, {_.state.abbrev} {pipe(_.postalCode, O.getOrElse(() => ""))}</p>
                {O.fold(constEmpty, (phoneAndExt: string) => <p className="mb-0">Phone: {phoneAndExt}</p>)(phoneWithExtension(O.some(issuer.phoneNumber), issuer.phoneExtension))}
              </div>
            )
          )}
          {pipe(
            props.data.customContact,
            mapOrEmpty(_ => <Markdown klasses={["mt-1", "last-child-mb-0"]} content={_.data} />)
          )}
          <ContactModalCallout
            btnText={"Email Us"}
            bondOfferingId={O.none}
            header={"Contact Us"}
            introText={
              <div className="d-flex flex-col">
                <p>Your message will be sent securely and someone will reply to you shortly.</p>
                <p className="mb-0">
                  Looking for direct contact information?
                  {" "}
                  <Anchor target="_self" route={{ ...team({ issuerId: issuer.id, issuerSlug: issuer.slug }), title: () => `View the ${teamTitle} page for details.` }} />
                </p>
              </div>
            }
            issuer={issuer}
            rfpId={O.none}
            user={O.map((u: UserWithRoles) => u.user)(user)}
            klasses="mt-2"
          />
        </AccentDividerSection>
      )(showContactUsSection)}
      {pipe(
        props.data.customPages,
        RA.map(customPage => <CustomPageSection
          klasses="accent-border-top"
          customPage={customPage}
          key={customPage.page.name}
        />)
      )}
    </DirectSitesPageDescriptionLayoutBase>
  );
};
