import { useCallback } from "react";
import * as O from "fp-ts/lib/Option";

import { pipe } from "@scripts/fp-ts";
import { ButtonPrimary } from "@scripts/react/components/Button";
import { FormModalDataLoaderWithState } from "@scripts/react/components/form/FormModal";
import { useConfig } from "@scripts/react/context/Config";
import { type CloseModalFn, type ModalOpen, useModal } from "@scripts/react/util/useModal";
import { rfpBidSubmissionBtnText } from "@scripts/syntax/rfp";

import rfpIcon from "@svgs/rfp.svg";

import { mapOrEmpty } from "../../Empty";
import { BidSubmissionForm } from "./BidSubmissionForm";
import { type BidSubmissionModalDataProps, initializeRfpBidSubmissionState } from "./BidSubmissionSyntax";

export const BidSubmissionModal = (props: BidSubmissionModalCalloutProps & BidSubmissionModalDataProps) => {
  const config = useConfig();

  return (
    <FormModalDataLoaderWithState
      customFormProps={{
        user: props.user,
        rfp: props.rfp,
        bidSubmission: props.bidSubmission,
        setBidSubmission: props.setBidSubmission,
        bidSubmissionTemplate: props.bidSubmissionTemplate,
        issuer: props.issuer,
        bidSubmissionState: props.bidSubmissionState,
        subscription: props.subscription,
      }}
      form={BidSubmissionForm}
      formHeader={O.none}
      formProps={{
        deleteButton: O.none,
        dismissAction: props.dismissAction,
        onSuccess: (_, d) => {
          props.setBidSubmission(d.data);
          props.successAction();
        },
      }}
      initialState={initializeRfpBidSubmissionState(config, props.user, props.rfp.data.id, props.bidSubmission, props.bidSubmissionTemplate)}
      modalProps={{
        dataMeta: {
          customDisplayName: O.none,
          svg: rfpIcon,
          type: "Bid Submission",
        },
        size: "modal-lg",
        open: props.modalOpen,
        action: "none",
      }}
    />
  );
};

type BidSubmissionModalCalloutProps = {
  modalOpen: ModalOpen;
  dismissAction: CloseModalFn;
  successAction: () => void;
};

export const BidSubmissionModalCallout = (
  props: BidSubmissionModalDataProps & { successAction?: BidSubmissionModalCalloutProps["successAction"] }
) => {
  const [modalOpen, openModal, closeModal] = useModal("Bid Submission Modal");

  const successAction = useCallback(() => {
    props.successAction?.();
    closeModal();
  }, [closeModal, props]);

  return pipe(
    rfpBidSubmissionBtnText(props.bidSubmissionState),
    mapOrEmpty(t => <div>
      <ButtonPrimary className="mt-0" onClick={openModal}>{t}</ButtonPrimary>
      <BidSubmissionModal
        {...props}
        dismissAction={closeModal}
        modalOpen={modalOpen}
        successAction={successAction}
      />
    </div>),
  );
};
