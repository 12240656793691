import type { PropsWithChildren } from "react";
import { useState } from "react";
import type { Dispatch } from "redux";
import V from "voca";

import type { ErrorHandlerApiReq } from "@scripts/api/methods";
import type { ErrorHandler } from "@scripts/api/subscription";
import { gaWatchlist } from "@scripts/api/subscription";
import { A, constVoid, E, flow, O, pipe, R, RNEA, TE, Th } from "@scripts/fp-ts";
import { type InvestorPortalOffering } from "@scripts/generated/models/investorPortalOffering";
import type * as IAT from "@scripts/generated/models/issuerActivityTypes";
import type { InvestorPortalRfp } from "@scripts/generated/models/rfp";
import type { TaggedContent } from "@scripts/generated/models/taggedContent";
import type { WithId } from "@scripts/generated/models/threadThrough";
import type * as V2Router from "@scripts/generated/routers/v2Router";
import { Svg } from "@scripts/react/components/Svg";
import type { Klass } from "@scripts/react/util/classnames";
import { klass, klassConditional, klassPropO } from "@scripts/react/util/classnames";
import type { RfpWithRelatedContent } from "@scripts/syntax/rfp";
import { positiveNonZeroNumberO } from "@scripts/util/numberFormat";
import { pluralize, pluralizeWithS } from "@scripts/util/pluralize";

import bookmarkAddIcon from "@svgs/bookmark-add-2color.svg";
import bookmarkCheckedIcon from "@svgs/bookmark-checked-2color.svg";
import bookmarkDeleteIcon from "@svgs/bookmark-delete-filled-2color.svg";
import checkmark from "@svgs/checkmark.svg";
import closeX from "@svgs/close-x.svg";
import plus from "@svgs/plus.svg";

import { notificationAdd } from "../state/notifications";
import type { BaseActions } from "../state/store";
import { ButtonLinkIcon } from "./Button";
import { refresh } from "./form/FormErrorMessages";
import type { TooltipDelay } from "./Tooltip";
import { defaultDuration, parseDelay, Tooltip, tooltipDelayTransition } from "./Tooltip";
export type LoginReasonO = V2Router.BaseAuthControllerLoginParams["reason"];
export type Offerings = InvestorPortalOffering["offering"] | IAT.OfferingItem["item"]["data"];
export type RFPs = IAT.RFPItem["item"]["data"] | WithId<TaggedContent<RfpWithRelatedContent>> | InvestorPortalRfp["rfp"];
export type SubscriptionItem = E.Either<Offerings, RFPs>;

export const makeHandleSubscribeError = (dispatch: Dispatch<BaseActions>): ErrorHandler =>
  <A,>(te: ErrorHandlerApiReq<A>) => {
    return te(
      () => {
        dispatch(notificationAdd({
          id: "subscription",
          title: "Error Changing Subscription",
          type: "danger",
          children: refresh,
        }));
      },
      constVoid
    );

  };

const subFold = (isSubscribed: boolean, isHoverable: boolean) => <A,>(onDeletable: () => A, onSubscribed: () => A, onHoverable: () => A) => {
  if (isSubscribed && isHoverable) {
    return onDeletable();
  } else if (isSubscribed) {
    return onSubscribed();
  } else {
    return onHoverable();
  }
};

export const makeOnSubscribeHandler = (
  isSubscribing: boolean,
  onToggle: () => void,
  onRollback: () => void,
  request: TE.TaskEither<unknown, unknown>,
): OnSubscribe => () => {
  onToggle();

  pipe(
    request,
    TE.bimap(
      () => {
        onRollback();
      },
      () => {
        gaWatchlist(isSubscribing);
      }
    ),
    TE.run
  );
};

export type OnSubscribe = () => void;

export type SubscriptionToggleBaseProps = {
  class?: Klass;
  containerKlass?: Klass;
  disableTooltip?: true;
  isHoverable: boolean;
  setIsHoverable: (isHoverable: boolean) => void;
  isSubscribed: boolean;
  onSubscribe: OnSubscribe;
  delay?: TooltipDelay;
  disabled?: boolean;
  id?: PropertyKey;
  delayIsHoverable?: false;
};

const SubscriptionToggleBase = (props: PropsWithChildren<SubscriptionToggleBaseProps>) => {
  const delayIsHoverable = props.delayIsHoverable ?? true;

  return <button
    aria-label={`${props.isSubscribed ? "Remove from" : "Add to"} Watchlist`}
    {...klassPropO(["toggle-subscription", "d-flex"])(props.class)}
    type="button"
    onClick={e => {
      e.stopPropagation();
      props.setIsHoverable(false);
      props.onSubscribe();
    }}
    data-checked={props.isSubscribed}
    data-hoverable={props.isHoverable}
    disabled={props.disabled}
    id={props.id?.toString()}
    key={props.id?.toString()}
    onMouseLeave={() => {
      if (delayIsHoverable) {
        globalThis.setTimeout(
          () => props.setIsHoverable(true),
          parseDelay(props.delay ?? tooltipDelayTransition)[1] + defaultDuration[1]
        );
      } else {
        props.setIsHoverable(true);
      }
    }}
  >
    {props.children}
  </button>;
};

type SubscriptionToggleWithTooltipProps = PropsWithChildren<
  Omit<SubscriptionToggleBaseProps, "isHoverable" | "setIsHoverable">
>;

export const SubscriptionToggleWithTooltip = (props: SubscriptionToggleWithTooltipProps) => {
  const [isHoverable, setIsHoverable] = useState(true);
  const subscriptionFold = subFold(props.isSubscribed, isHoverable);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  return <div {...klassConditional("tooltip-open", klassPropO("subscription-toggle w-fit-content")(props.containerKlass).className)(tooltipOpen)}>
    <SubscriptionToggleBase {...props} isHoverable={isHoverable} setIsHoverable={setIsHoverable}>
      {(!props.disableTooltip && !props.disabled)
        ? <Tooltip
          delay={props.delay || tooltipDelayTransition}
          description={{
            type: "DescriptionContentElement",
            element:
              <ButtonLinkIcon
                onClick={(e) => {
                  e.stopPropagation();
                  props.onSubscribe();
                }}
                className={subscriptionFold(() => "remove-from-subscription", () => "add-to-subscription", () => "add-to-subscription")}
                icon={subscriptionFold(() => closeX, () => checkmark, () => plus)}
                textOrAriaLabel={E.left(subscriptionFold(() => "Remove from Watchlist", () => "Added to Watchlist", () => "Add to Watchlist"))}
              />,
          }}
          offset={[0, 6]}
          placement="top-start"
          muted={props.disabled}
          addKlassToTooltip="subscription-toggle-tooltip no-min-width"
          addClassToTargetNode={"subscription-tooltip-trigger d-flex"}
          onShow={() => setTooltipOpen(true)}
          onHide={() => setTooltipOpen(false)}
        >{props.children}</Tooltip>
        : props.children
      }</SubscriptionToggleBase>
  </div>;
};

export const SubscriptionToggleIcon = (props: SubscriptionToggleWithTooltipProps) => (
  <SubscriptionToggleWithTooltip
    onSubscribe={props.onSubscribe}
    isSubscribed={props.isSubscribed}
    disabled={props.disabled}
    delay={props.delay}
    class={klassPropO("icon-button icon-toggle-subscription")(props.class).className}
    containerKlass={props.containerKlass}
    id={props.id}
    disableTooltip={props.disableTooltip}
  >
    <div className="add-toggle"><Svg src={bookmarkAddIcon} /></div>
    <div className="checked-toggle"><Svg src={bookmarkCheckedIcon} /></div>
    <div className="delete-toggle"><Svg src={bookmarkDeleteIcon} /></div>
  </SubscriptionToggleWithTooltip>
);

export const SubscriptionToggleBtn = (props: SubscriptionToggleWithTooltipProps) => (
  <SubscriptionToggleWithTooltip
    onSubscribe={props.onSubscribe}
    disableTooltip={props.disableTooltip}
    isSubscribed={props.isSubscribed}
    delay={props.delay}
    disabled={props.disabled}
    class={klassPropO("btn btn-primary btn-toggle-subscription")(props.class).className}
    id={props.id}
    delayIsHoverable={false}
  >
    <div className="add-toggle"><Svg src={bookmarkAddIcon} />WatchList</div>
    <div className="checked-toggle"><Svg src={bookmarkCheckedIcon} />Added</div>
    <div className="delete-toggle"><Svg src={bookmarkDeleteIcon} />Remove</div>
  </SubscriptionToggleWithTooltip>
);

export const SubscriptionToggleIconCard = (
  props: Omit<SubscriptionToggleBaseProps, "isHoverable" | "setIsHoverable">
) => (
  <SubscriptionToggleIcon
    onSubscribe={props.onSubscribe}
    id={props.id}
    isSubscribed={props.isSubscribed}
    class={"subscription-icon-card"}
    containerKlass={klassPropO("subscription-icon-card-container")(props.containerKlass).className}
    delay={props.delay}
    disabled={props.disabled}
  />
);

type SubscriptionCopyOfferingKind = "Bond" | "RFP";
type SubscriptionCopyKind = "Sector" | "Issuer" | "State" | SubscriptionCopyOfferingKind;
const makeKindCopy = (kind: SubscriptionCopyKind) => kind === "RFP" ? kind : V.lowerCase(kind);
const makeEmptyCounterCopy = (kind: SubscriptionCopyKind) => `There are no ${makeKindCopy(kind)}s in your watchlist.`;
const makeThereIsCopy = (count: number, kind: SubscriptionCopyKind) => `There ${pluralize("is", "are")(count)} ${count} ${pluralizeWithS(makeKindCopy(kind))(count)}`;

export const makeSubscriptionCounterCopy = (firstSubCount: number, firstSubPostFix: string, secondSubCount: number, secondSubPostFix: string) => pipe(
  Th.fromOptions(positiveNonZeroNumberO(firstSubCount), positiveNonZeroNumberO(secondSubCount)),
  O.map(Th.fold(
    firstCount => `${firstCount} ${firstSubPostFix}`,
    secondCount => `${secondCount} ${secondSubPostFix}`,
    (firstCount, secondCount) => `${firstCount} ${firstSubPostFix} and ${secondCount} ${secondSubPostFix}`,
  )),
);

export const makeSectorCounterCopy: (count: number) => string = flow(positiveNonZeroNumberO, O.fold(
  () => makeEmptyCounterCopy("Sector"),
  count => `${makeThereIsCopy(count, "Sector")} in your watchlist.`
));

export const makeIssuerCounterCopy = (directlyAddedCount: number, sectorsAddedCount: number) => pipe(
  makeSubscriptionCounterCopy(
    directlyAddedCount,
    "added directly",
    sectorsAddedCount,
    "added from your sectors, states, and issuers"
  ),
  O.fold(
    () => makeEmptyCounterCopy("Issuer"),
    copy => `${makeThereIsCopy(directlyAddedCount + sectorsAddedCount, "Issuer")} in your watchlist: ${copy}.`
  )
);

export const makeOfferingCopy = (directlyAddedCount: number, sectorsAddedCount: number, kind: SubscriptionCopyOfferingKind) => pipe(
  makeSubscriptionCounterCopy(
    directlyAddedCount,
    "added directly",
    sectorsAddedCount,
    "added from your sectors, states, and issuers"
  ),
  O.fold(
    () => makeEmptyCounterCopy(kind),
    copy => `${makeThereIsCopy(directlyAddedCount + sectorsAddedCount, kind)} in your watchlist: ${copy}.`
  )
);

const dontAddCommaLength = 2;
export const makeModalSubscriptionCopy = <A extends Record<string, ReadonlyArray<unknown>>>(counts: A) => pipe(
  O.of(counts),
  O.map(R.map(items => positiveNonZeroNumberO(items.length))),
  O.map(flow(
    R.compact,
    R.toArray,
    countArray => pipe(
      countArray,
      A.mapWithIndex((idx, [countName, countValue]) => ` ${countArray.length > 1 && (idx + 1) === countArray.length ? "and " : ""}${countValue} ${V.lowerCase(pluralizeWithS(countName)(countValue))}`),
    ),
    countCopy => countCopy.length <= dontAddCommaLength ? countCopy : A.intersperse(",")(countCopy),
  )),
  O.chain(RNEA.fromArray),
  O.map(_ => _.join("")),
  O.fold(
    () => "You have not added anything to your watchlist yet during this session.",
    copy => `You have added${copy} to your watchlist during this session.`
  )
);

export const makeStateCounterCopy: (count: number) => string = flow(positiveNonZeroNumberO, O.fold(
  () => makeEmptyCounterCopy("State"),
  count => `${makeThereIsCopy(count, "State")} in your watchlist.`
));


const subscriptionCounterKlass = (count: number) => klassConditional("no-subscriptions", "subscription-count-container icon-2-color-teal mb-0")(count === 0);

type SubscriptionCountProps = {
  delay?: TooltipDelay;
  tooltipText: string;
  count: number;
};

export const SubscriptionCount = (props: SubscriptionCountProps) =>
  <Tooltip
    delay={props.delay || tooltipDelayTransition}
    description={{
      type: "DescriptionContentElement",
      element: <>
        <p>{props.tooltipText}</p>
        {props.count > 0 && <div {...klass("font-sans-italic-400")}>Note: applied filters may change the number of matches shown below.</div>}
      </>,
    }}
    placement="top-start"
    muted={props.count === 0}
    addKlassToTooltip="no-min-width"
  >
    <Svg src={bookmarkCheckedIcon} />{props.count}
  </Tooltip>;

export const SubscriptionCounterHeader = (props: SubscriptionCountProps) => <h5 {...subscriptionCounterKlass(props.count)}><SubscriptionCount count={props.count} tooltipText={props.tooltipText} /></h5>;

export const SubscriptionCounterModal = (props: SubscriptionCountProps) => <p {...subscriptionCounterKlass(props.count)}><SubscriptionCount count={props.count} tooltipText={props.tooltipText} /></p>;
